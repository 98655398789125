<template>
  <b-card>
    <b-row v-if="images.length">
      <b-col
        v-for="(image, index) in images"
        :key="image.src || image"
        class="text-center mb-2"
        xs="12"
        sm="6"
        lg="4"
        xl="2"
      >
        <div
          v-if="typeof image ==='object'"
          class="image"
          :title="image.title"
          :style="{ backgroundImage: 'url(' + image.src + ')' }"
          @click="imageIndex = index"
        >
          <p class="meta">
            {{ image.title }}
          </p>
        </div>
        <div
          v-else
          class="image"
          :title="`pic ${index + 1}`"
          :style="{ backgroundImage: 'url(' + image + ')' }"
          @click="imageIndex = index"
        ></div>
      </b-col>
    </b-row>
    <template v-else>
      No images
    </template>

    <cool-light-box
      :items="images || []"
      :index="imageIndex"
      @close="imageIndex = null"
    />
  </b-card>
</template>

<script>
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: 'CardImages',
  components: {
    CoolLightBox
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      imageIndex: null
    }
  }
}
</script>

<style scoped>
.image {
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100%;
}

.meta {
  background-color: black;
  color: white;
  font-size: 11px;
}
</style>
