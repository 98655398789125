<template>
  <div>
    <ul class="list-group">
      <li
        v-if="showRevoteMsg && card.restarted"
        class="list-group-item"
      >
        <span class="text-danger">
          <span class="font-weight-bold">REVOTE:</span>
          Возможно ваш ответ неверный. Обсудите в чате и переголосуйте.
        </span>
      </li>
      <li
        v-if="card.adminComment"
        class="list-group-item"
      >
        admin comment: <span class="font-weight-bold text-warning">{{ card.adminComment }}</span>
      </li>
    </ul>
    <ul class="list-group list-group-horizontal text-center mt-2 list-group-condensed">
      <li
        v-if="$auth.check('Admin')"
        class="list-group-item flex-fill"
      >
        votes<br><span class="font-weight-bold">{{ card.votes }}</span>
      </li>
      <li class="list-group-item flex-fill">
        alias<br><span class="font-weight-bold">{{ card.alias }}</span>
      </li>
      <li class="list-group-item flex-fill">
        qty<br><span class="font-weight-bold text-danger">{{ card.qty }}</span>
      </li>
      <li class="list-group-item flex-fill">
        weight<br><span class="font-weight-bold">{{ card.weight }}<span
        v-if="keepaWeight"
        class="text-warning"
      > ({{ keepaWeight }})</span></span>
      </li>
    </ul>
    <ul class="list-group mt-2">
      <li class="list-group-item">
        from: <span class="font-weight-bold">{{ card.from }}</span>
      </li>
      <li class="list-group-item">
        comment: <span class="font-weight-bold">{{ card.comment }}</span>
      </li>
    </ul>
    <card-images
      v-if="card.images"
      class="my-3"
      :images="card.images"
    />
    <ul class="list-group list-group-condensed">
      <li class="list-group-item pl-2">
        QTY: <span
        class="font-weight-bold"
        :class="{'text-danger' : card.qty > 1}"
      >
          {{ card.qty }}
        </span>
      </li>
    </ul>
    <b-form
      autocomplete="off"
      class="mt-2"
    >
      <div v-if="keepaText">
        <span class="font-weight-bold">Amazon.com:</span> <span
        class="text-warning"
        v-text="keepaText"
      />
      </div>
      <b-form-group
        v-if="!report"
        :state="asinValidationState"
        invalid-feedback="Не похоже на asin"
      >
        <b-input-group>
          <b-form-input
            v-if="!acpEnabled"
            v-model="asin"
            :state="asinValidationState"
            class="text-uppercase"
            placeholder="ASIN"
            autofocus
          />
        </b-input-group>
        <vue-typeahead-bootstrap
          v-if="acpEnabled"
          v-model="asin"
          :serializer="item => item.text"
          placeholder="Start typing name"
          :data="autocomplete"
          show-all-results
          :min-matching-chars="1"
          :max-matches="15"
          @hit="selectAutocomplete"
        >
          <template #suggestion="{ data, htmlText }">
            <b>{{ data.value }}</b>:
            <template v-if="data.comment">
              <span
                class="text-danger"
                v-text="data.comment"
              />:
            </template>
            <span v-html="htmlText" />
          </template>
          <template #append>
            <b-button
              title="Search ASIN by name"
              @click="asin = card.name"
            >
              <b-icon icon="clipboard-check" />
            </b-button>
          </template>
        </vue-typeahead-bootstrap>
      </b-form-group>
    </b-form>
    <template v-if="report">
      <b-form-group description="Select an option or select 'Other' to describe the problem">
        <b-form-select
          v-model="problem"
          :options="problems"
        />
      </b-form-group>
      <b-form-group
        v-if="problem === ''"
        description="Describe the problem"
        invalid-feedback="Problem can't be blank"
        :state="ownProblemValidationState"
      >
        <b-form-input
          v-model="ownProblem"
          :state="ownProblemValidationState"
        />
      </b-form-group>
    </template>

    <div class="text-left">
      <b-form>
        <slot
          :can-vote="canVote"
          :vote-asin="report ? null : asin.toUpperCase()"
          :vote-problem="report ? problem || ownProblem : null"
          :is-alert="ownProblem !== null"
        />

        <template v-if="!report">
          <b-button
            class="mr-2 mb-2"
            :disabled="!asinOrUpc || !!keepaText || productDataLoading"
            @click="check"
          >
            <b-spinner
              v-if="productDataLoading"
              small
            />
            Check
          </b-button>
          <b-button
            class="mr-2 mb-2"
            @click="openReport()"
          >
            Problem
          </b-button>
        </template>
        <b-button
          v-else
          class="mr-2 mb-2"
          variant="outline-secondary"
          @click="report = false"
        >
          Cancel problem
        </b-button>
      </b-form>
    </div>

    <card-images
      v-if="productGalleryVisible"
      class="my-3"
      :images="productGallery"
    />

    <card-images
      v-if="keepaGalleryVisible"
      class="my-3"
      :images="keepaGallery"
    />
  </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ApiService from '@/ApiService'
import { getRequestErrorText, isAsin, isAsinOrUpc } from '@/utils'
import { debounce } from 'vue-debounce'
import CardImages from '@/components/CardImages'

export default {
  name: 'CardVote',
  components: {
    CardImages,
    VueTypeaheadBootstrap
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    showRevoteMsg: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      asin: '',
      report: false,
      problem: '',
      problems: [
        'Не видно / Не совпадает заявленное количество',
        'Refurbished / Used',
        'На фото не видно цвет, точную модель, размер',
        'Повтор',
        'Bundle / Не заводской набор',
        'Разные цвета / Разные модели в одной поставке',
        'Не уедет одним паком',
        'На фото другая модель, цвет, размер',
        'В заголовке не указана модель, цвет, размер',
        'Вес не совпадает',
        'Стоковое фото',
        'Ошибка',
        {
          value: '',
          text: 'Другое'
        }
      ],
      ownProblem: null,
      search: null,
      autocomplete: [],
      acpEnabled: this.card.acpEnabled,
      keepaText: '',
      keepaWeight: null,
      productDataLoading: false,
      productGallery: [],
      keepaGallery: [],
      productGalleryVisible: false,
      keepaGalleryVisible: false
    }
  },
  computed: {
    asinOrUpc () {
      return isAsinOrUpc(this.asin)
    },
    isAsin () {
      return isAsin(this.asin)
    },
    asinValidationState () {
      return this.asin ? this.isAsin : null
    },
    ownProblemValidationState () {
      return !!this.ownProblem
    },
    canVote () {
      return !!(this.report ? this.problem || this.ownProblem : this.asinValidationState)
    }
  },
  watch: {
    card () {
      this.report = false
      this.asin = ''
      this.problem = this.problems[0]
      this.ownProblem = null
      this.keepaText = ''
      this.keepaWeight = null
      this.productGallery = []
      this.keepaGallery = []
      this.productGalleryVisible = false
      this.keepaGalleryVisible = false
    },
    asin: debounce(function (search) {
      if (this.acpEnabled && search) {
        ApiService.get(`/autocomplete/suggest?term=${search}`)
          .then(response => {
            this.autocomplete = response.data
          })
          .catch(error => {
            this.autocomplete = []

            this.$bvToast.toast(getRequestErrorText(error), {
              title: 'Autocomplete error',
              variant: 'danger'
            })
          })
      } else {
        this.autocomplete = []
      }
      this.keepaText = ''
    }, '500ms')
  },
  created () {
    this.$root.$on('Votes', this.increaseVotes)
  },
  destroyed () {
    this.$root.$off('Votes', this.increaseVotes)
  },
  methods: {
    openReport () {
      this.problem = this.problems[0]
      this.report = true
    },
    selectAutocomplete (item) {
      this.asin = item.value
    },
    check () {
      this.productDataLoading = true

      ApiService.get(`/asin/${this.asin}/check`)
        .then(response => {
          this.keepaText = `${response.data.asin} - ${response.data.salesRank} - ${response.data.name}`
          this.keepaWeight = response.data.weight
          if (response.data.images.length) {
            this.productGalleryVisible = true
            this.productGallery = response.data.images
          }
          if (response.data.keepaImages.length) {
            this.keepaGalleryVisible = true
            this.keepaGallery = response.data.keepaImages
          }
        })
        .catch(error => {
          this.keepaText = ''
          this.keepaWeight = null
          this.productGallery = []
          this.productGalleryVisible = false
          this.keepaGallery = []
          this.keepaGalleryVisible = false
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Product info loading error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.productDataLoading = false
        })
    },
    increaseVotes (payload) {
      if (this.card.id === payload.cardId) {
        this.card.votes = payload.votes
      }
    }
  }
}
</script>

<style scoped>
.list-group-condensed .list-group-item {
  padding: .1rem .1rem;
}
</style>
